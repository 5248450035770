<template>
    <div id="pageContainer">
        <SpinnerOverlay v-if="!debug" class="z-index-999" />
        <b-button id="printReportBtn" v-if="debug" @click="printReport">Print Report</b-button>

        <div id="generatingReportContainer" v-if="!debug">
            <h1 id="generatingReport" class="text-primary">{{ t('generatingReport') }}</h1>
        </div>

        <div v-if="preOpReport" id="main">
            <div
                v-for="(page, pageNum) in pages"
                :key="'P' + pageNum"
                class="preOpReportPage"
                :class="{
                    forcePageBreakBefore: pageNum != 0,
                }"
            >
                <div id="pageHeader">
                    <img id="headerImg" src="@/assets/stella-logo-with-name.png" />
                    <div v-if="pageNum == 0" id="headerRight">{{ t('invLookupReport_Title') }}</div>

                    <div
                        v-if="pageNum != 0"
                        id="patientDataContainer"
                        class="patientDataContainerPageX"
                    >
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_Customer') }}</div>
                            <div id="patientDataClinicName" class="patientDataValue">
                                {{ definedAndNotBlankObj(preOpReport, 'clinicName', '—') }}
                                <span class="noEmphasis">
                                    ({{
                                        definedAndNotBlankObj(preOpReport, 'customerOcosId', '—')
                                    }})
                                </span>
                            </div>
                        </div>
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_Surgeon') }}</div>
                            <div id="patientDataSurgeon" class="patientDataValue">
                                {{ definedAndNotBlankObj(preOpReport, 'surgeon', '—') }}
                                <span class="noEmphasis">
                                    ({{ definedAndNotBlankObj(preOpReport, 'surgeonOcosId', '—') }})
                                </span>
                            </div>
                        </div>
                        <div v-if="!activeCustomer.hidePatientNames" class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_FirstName') }}</div>
                            <div id="patientDataFirstName" class="patientDataValue">
                                {{ definedAndNotBlankObj(preOpReport, 'firstName', '—') }}
                            </div>
                        </div>
                        <div v-if="!activeCustomer.hidePatientNames" class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_LastName') }}</div>
                            <div id="patientDataLastName" class="patientDataValue">
                                {{ definedAndNotBlankObj(preOpReport, 'lastName', '—') }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="titleContainer"
                    class="d-flex justify-content-between"
                    :class="{
                        page1: pageNum == 0,
                    }"
                >
                    <section>
                        <h2 class="mb-0 font-weight-bold">
                            {{ definedAndNotBlankObj(preOpReport, 'patientOcosId', '—') }}
                            <span class="h5 text-muted">
                                {{ genderHandling(preOpReport) }}
                            </span>
                        </h2>
                        <p class="h6 text-muted">
                            {{ t('patientId') }}
                        </p>
                    </section>
                    <section v-if="!activeCustomer.hidePatientNames && pageNum == 0">
                        <h2 class="mb-0 names">
                            {{
                                namesFormatter(
                                    definedAndNotBlankObj(preOpReport, 'lastName', ''),
                                    definedAndNotBlankObj(preOpReport, 'firstName', '')
                                ) || '&nbsp;'
                            }}
                        </h2>
                        <p class="h6 text-muted text-right">
                            {{ t('NameLastFirst') }}
                        </p>
                    </section>
                </div>

                <div v-if="pageNum == 0" id="patientDataContainer">
                    <div id="patientDataCol1">
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_Birthdate') }}</div>
                            <div id="patientDataDOB" class="patientDataValue">
                                {{ date(preOpReport.dob, {isUTC: false}) || '—' }}
                                <span class="noEmphasis">
                                    ({{ definedAndNotBlankObj(preOpReport, 'yearsOld', '—') }}
                                    {{ t('preopreport_years') }})
                                </span>
                            </div>
                        </div>
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_Customer') }}</div>
                            <div id="patientDataClinicName" class="patientDataValue">
                                {{ definedAndNotBlankObj(preOpReport, 'clinicName', '—') }}
                                <span class="noEmphasis">
                                    ({{
                                        definedAndNotBlankObj(preOpReport, 'customerOcosId', '—')
                                    }})
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="patientDataCol2">
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_Surgeon') }}</div>
                            <div id="patientDataSurgeon" class="patientDataValue">
                                {{ definedAndNotBlankObj(preOpReport, 'surgeon', '—') }}
                                <span class="noEmphasis">
                                    ({{ definedAndNotBlankObj(preOpReport, 'surgeonOcosId', '—') }})
                                </span>
                            </div>
                        </div>
                        <div class="patientDataItem">
                            <div class="patientDataLabel">{{ t('preopreport_SurgeryDate') }}</div>
                            <div id="patientDataSurgeryDate" class="patientDataValue">
                                {{ date(preOpReport.surgeryDate) || '—' }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="preOpReport.eye"
                    id="resultsContainer"
                    :class="{
                        resultsContainerPageX: pageNum != 0,
                    }"
                >
                    <div id="resultsContainerRow">
                        <div class="eyeContainer">
                            <div
                                id="eyeResult"
                                :class="{
                                    eyeResultPageX: pageNum != 0,
                                }"
                            >
                                <div class="eyeResultHeader">
                                    <div id="odEyeTitle" class="eyeTitle">
                                        {{ preOpReport.eye.name }}
                                    </div>
                                    <img
                                        id="odEye"
                                        v-if="preOpReport.eye.name == 'OD'"
                                        class="eyeResultHeaderImg"
                                        src="@/assets/eye_icon_black.svg"
                                    />

                                    <img
                                        id="odEye"
                                        v-else
                                        class="eyeResultHeaderImg"
                                        src="@/assets/eye_icon_gray.svg"
                                    />

                                    <img
                                        id="sdEye"
                                        v-if="preOpReport.eye.name == 'OS'"
                                        class="eyeResultHeaderImg"
                                        src="@/assets/eye_icon_black.svg"
                                    />

                                    <img
                                        id="sdEye"
                                        v-else
                                        class="eyeResultHeaderImg"
                                        src="@/assets/eye_icon_gray.svg"
                                    />

                                    <div id="targetLensLabel" v-if="preOpReport.targetLensName">
                                        {{ t('invLookupReport_TargetLens') }}
                                    </div>

                                    <div id="targetLensName">{{ targetLensDescription }}</div>
                                </div>

                                <!-- Handle for no lenses -->
                                <div
                                    v-if="
                                        preOpReport.eye.lenses == undefined ||
                                        preOpReport.eye.lenses.length == 0
                                    "
                                    class="sectionTable"
                                >
                                    <table>
                                        <tr
                                            class="inventoryLookupLensHr"
                                            v-html="generateLensTableHeaderRow()"
                                        ></tr>
                                    </table>

                                    <div class="emptyData">
                                        {{ t('preopreport_LensesNotOrdered') }}
                                    </div>
                                </div>

                                <!-- Handle for lenses found -->
                                <template
                                    v-else
                                    v-for="(section, sectionIndex) in sections.slice(
                                        page.startSection,
                                        page.endSection + 1
                                    )"
                                >
                                    <div
                                        id="lensesContainer"
                                        class="sectionContainer"
                                        v-if="filteredSectionLenses(sectionIndex, page).length > 0"
                                        :key="'S' + sectionIndex"
                                    >
                                        <div
                                            class="sectionTitle"
                                            :class="onlyStaarLenses() && 'dontShowSectionTitle'"
                                        >
                                            {{ section }}
                                            {{ sectionContinuedText(pageNum, sectionIndex) }}
                                        </div>

                                        <div class="sectionTable">
                                            <table>
                                                <tr
                                                    class="inventoryLookupLensHr"
                                                    v-html="generateLensTableHeaderRow(section)"
                                                ></tr>
                                                <tr
                                                    v-for="(
                                                        lens, lensIndex
                                                    ) in filteredSectionLenses(sectionIndex, page)"
                                                    :key="'L' + lensIndex"
                                                >
                                                    <template v-if="isToric">
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    0,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    1,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    2,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    4,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    5,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    6,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    7,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    8,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                    </template>
                                                    <template v-else>
                                                        <td
                                                            v-if="
                                                                section ==
                                                                t('invLookupReport_Consignment')
                                                            "
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    0,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    1,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    2,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    4,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                        <td
                                                            v-html="
                                                                definedAndNotBlankArrIndex(
                                                                    lens,
                                                                    8,
                                                                    '—',
                                                                    undefined
                                                                )
                                                            "
                                                            class="text-nowrap"
                                                        ></td>
                                                    </template>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="footerLabel" class="pt-4 pb-4">
                    <div id="footerLabelLeft">
                        <img id="footerImg" src="@/assets/StaarSurgical-horiz.png" />
                        <div>
                            {{ new Date() | date({format: dateFormat, isUTC: false}) }}
                            &nbsp;&nbsp; | &nbsp;&nbsp;
                            {{ definedAndNotBlankObj(currentUser, 'firstName', '—') }}
                            {{ definedAndNotBlankObj(currentUser, 'lastName', '—') }}
                            &nbsp;&nbsp; | &nbsp;&nbsp;
                            {{
                                t('pageOf', {
                                    pageNum: pageNum + 1,
                                    length: pages.length,
                                })
                            }}
                        </div>
                        <div>&nbsp;</div>
                    </div>

                    <div id="inventoryLevelsWarning">
                        {{ t('invLookupReport_InventoryLevelsWarning') }}
                    </div>

                    <div id="footerRight">
                        <!--img id="lensTypeImg" :src="lensTypeImgUrl" /-->
                        <div v-if="1" class="calculationTitleContainer">
                            {{ t('preopreport_CalculatedWith') }}
                            {{ definedAndNotBlankObj(preOpReport.eye, 'calculationVersion', '—') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import {DEFAULT_DATE_FORMAT} from '@/config';
import {definedAndNotBlankObj} from '@/utilities/object';
import {
    genderHandling,
    formatLensNum,
    padNumber,
    namesFormatter,
    decimalSeparatorFormatter,
} from '@/utilities/formatters';
import SpinnerOverlay from '@/views/layout/SpinnerOverlay';
import {generatePdfDocument, displayPdfDocument} from '@/utilities/pdfUtils';
import {getLogo, inventoryReportSelectorList} from '@/constants/report';
import date from '@/filters/date';

export default {
    name: 'InventoryLookupReport',
    components: {
        SpinnerOverlay,
    },
    data() {
        return {
            isToric: false,
            inventoryLookupData: {},
            maxPageRows: 31,
            pages: [],
            targetLensLength: null,
            targetLensSph: null,
            targetLensCyl: null,
            targetLensAxis: null,
            sections: [
                //this.t('invLookupReport_Consignment'),
                //this.t('invLookupReport_Local'),
                //this.t('invLookupReport_STAAR'),
            ],
            preOpReport: null,
            preOpReportDebug: {
                isToric: true,
                firstName: 'Bartholomewz',
                lastName: 'Featherstonethrowz',
                gender: 'M',
                customerOcosId: '987654321z',
                dob: '11/21/1980z',
                yearsOld: '39z',
                patientOcosId: '123456789z',
                clinicName: 'West Coast Clinicz',
                surgeon: 'Dr. John Medinaz',
                surgeonOcosId: '5678901z',
                surgeryDate: '2/2/2022z',
                targetLensName: 'Toric Myopic 13.2mm -8.00 +1.00 x090',
                eye: {
                    name: 'OD',
                    calculationVersion: 'v6.00USz',
                    /* eslint-disable */
                    lenses: [
                        [
                            '1T1234z',
                            'VTICM5_12.6',
                            'Evo+ Visian ICL',
                            '1 week',
                            -8.0,
                            2.0,
                            80,
                            '-00.75/ +00.13/090',
                            0.15,
                            0,
                        ],
                        [
                            '2T1234567z',
                            'VTICM5_12.6',
                            'Evo+ Visian ICL',
                            '2 weeks',
                            -7.0,
                            null,
                            90,
                            '-00.75/ +00.13/090',
                            0.01,
                            0,
                        ],
                        [
                            '3T1234567z',
                            'VTICM5_12.6',
                            'Evo+ Visian ICL',
                            '1 month',
                            -7.0,
                            1.0,
                            null,
                            '-00.75/ +00.13/090',
                            null,
                            0,
                        ],
                        [
                            '4T1234z',
                            'VTICM5_12.6',
                            'Evo+ Visian ICL',
                            '1 week',
                            -7.0,
                            2.0,
                            90,
                            '-00.75/ +00.13/090',
                            0.15,
                            1,
                        ],
                    ],
                    /* eslint-enable */
                },
            },
            pagePrinted: false,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            preOpReportRemote: 'preopreport/preOpReport',
            activeCustomer: 'user/activeCustomer',
        }),
        dateFormat() {
            return `${DEFAULT_DATE_FORMAT} HH:mm`;
        },
        decimalSeparator() {
            const {decimalSeparator} = this.currentUser;
            return decimalSeparator;
        },
        targetLensDescription() {
            return decimalSeparatorFormatter(
                definedAndNotBlankObj(this.preOpReport, 'targetLensName', ''),
                this.decimalSeparator
            );
        },
        debug() {
            return this.$route.query.debug;
        },
        lensTypeImgUrl() {
            const brandLogo = getLogo(this.preOpReport.brandLogo);
            return require(`@/assets/reports/${brandLogo}.png`);
        },
    },
    methods: {
        formatLensNum,
        padNumber,
        genderHandling,
        definedAndNotBlankObj,
        namesFormatter,
        date,
        ...mapMutations({
            showWarningModal: 'message/showWarningModal',
        }),

        /** Generate a pdf report and display it in an iframe */
        async printReport() {
            const pdfBlob = await generatePdfDocument(
                '.preOpReportPage',
                inventoryReportSelectorList,
                this.t('reportTitle'),
                this.$html2canvas,
                this.debug
            );
            displayPdfDocument(pdfBlob);
        },

        definedAndNotBlankArrIndex(array, index, failStr, blankArrayString) {
            var styleClass = '';
            if (array == undefined) return failStr;

            if (array.length <= index) {
                if (blankArrayString != undefined) return blankArrayString;

                return failStr;
            }

            if (typeof array[index] == 'string' && array[index].length == 0) return failStr;

            if (array[index] == undefined) return failStr;

            switch (index) {
                case 1: //Model
                    return `
                     <div>
                        <span>${this.parseLensLength(array[index])}</span>
                        <span class="${
                            this.lensLengthDiff(array[index]) != this.targetLensLength
                                ? 'cellRedTextFound'
                                : ''
                        }">
                        ${this.lensLengthDiff(array[index])}
                        </span>
                    </div>
                    `;
            }

            if (this.isToric) {
                switch (index) {
                    case 4: //Sph
                        if (array[index] != this.targetLensSph) styleClass = 'cellRedTextFound';
                        break;
                    case 5: //Cyl
                        if (array[index] != this.targetLensCyl) styleClass = 'cellRedTextFound';
                        break;
                    case 6: //Axis
                        if (array[index] != this.targetLensAxis) styleClass = 'cellRedTextFound';
                        break;
                }
            }

            return `<div class="${styleClass}">${array[index]}</div>`;
        },
        sectionContinuedText(pageNum, sectionIndex) {
            let output = '';

            //Note- This could be improved to only include continue
            //  labels for sections that have been split. Not sure if
            //  the spec would prefer that.
            if (pageNum != 0 && sectionIndex == 0)
                output = `(${this.t('invLookupReport_continued')})`;

            return output;
        },
        filteredSectionLenses(sectionIndex, page) {
            let sectionIndexAdjusted = sectionIndex + page.startSection;

            let lenses = this.preOpReport.eye.lenses.filter(
                (valArr) => valArr[9] == sectionIndexAdjusted
            );

            let startIndex = 0;
            let endIndex = lenses.length;

            //Use page.startLensIndex if section split from last page
            if (sectionIndexAdjusted == page.startSection) {
                if (page.startLensIndex != undefined) startIndex = page.startLensIndex;
            }

            //Use page.endLensIndex if section is last section on page
            if (sectionIndexAdjusted == page.endSection) {
                if (page.endLensIndex != undefined) endIndex = page.endLensIndex;
            }

            let output = lenses.slice(startIndex, endIndex);

            return output;
        },
        generatePages() {
            //Todo - Assign lenses to sections

            let pageObjTemplate = {
                startSection: 0,
                endSection: undefined,
                startLensIndex: 0,
                endLensIndex: undefined,
            };

            let currentPage = {...pageObjTemplate};
            let currentSectionOnPageIndex = 0;

            //First page is minus 5, first section is minus 3
            let numOfRowsLeftOnThisPage = this.maxPageRows - 5 - 3;

            //Loop through sections
            for (
                let currentSectionIndex = 0;
                currentSectionIndex < this.sections.length;
                currentSectionIndex++
            ) {
                currentPage.endSection = currentSectionIndex;

                //Get lenses for this section
                let lenses = this.preOpReport.eye.lenses.filter(
                    (valArr) => valArr[9] == currentSectionIndex
                );

                if (lenses.length == 0) {
                    //No lenses in this section so continue to next section
                    continue;
                }

                //We have lenses, so determine if section title will fit in this page
                //Section title height on page 1
                if (currentSectionOnPageIndex != 0) numOfRowsLeftOnThisPage -= 5.5; //Section title height on all other pages

                if (numOfRowsLeftOnThisPage < 0) {
                    //Page can't fit this section title so end this page
                    currentPage.endSection--;
                    this.pages.push(currentPage);

                    //Create a new page
                    currentPage = {...pageObjTemplate};
                    currentPage.startSection = currentSectionIndex;
                    currentPage.endSection = currentSectionIndex;
                    numOfRowsLeftOnThisPage = this.maxPageRows - 3;
                    currentSectionOnPageIndex = 0;
                }

                //Determine how many lenses can fit on this page
                let lensesToShow = lenses.length;
                for (;;) {
                    if (numOfRowsLeftOnThisPage > lensesToShow) {
                        //All lenses will fit on this page
                        numOfRowsLeftOnThisPage -= lensesToShow;
                        currentSectionOnPageIndex++;
                        break;
                    } else {
                        //Lenses will need to be split to a new page
                        if (currentPage.startSection == currentSectionIndex) {
                            currentPage.endLensIndex =
                                currentPage.startLensIndex + numOfRowsLeftOnThisPage;
                        } else currentPage.endLensIndex = numOfRowsLeftOnThisPage;

                        this.pages.push(currentPage);

                        lensesToShow -= numOfRowsLeftOnThisPage;

                        //Create a new page
                        currentPage = {...pageObjTemplate};
                        currentPage.startSection = currentSectionIndex;
                        currentPage.startLensIndex = lenses.length - lensesToShow;
                        currentPage.endSection = currentSectionIndex;
                        numOfRowsLeftOnThisPage = this.maxPageRows - 3;
                        currentSectionOnPageIndex = 0;

                        if (lensesToShow == 0) break;
                    }
                }
            }

            //Push the last page
            this.pages.push(currentPage);
        },
        generateTestData(numSectionsMax, numLensesMax) {
            let sections = [];
            let lenses = [];

            let numSections = Math.floor(Math.random() * numSectionsMax);

            for (let i = 0; i < numSections; i++) {
                if (i == 0) sections.push(this.t('invLookupReport_STAAR'));
                else sections.push(`Section_${i}`);
            }

            this.sections = sections;

            let numLenses = Math.floor(Math.random() * numLensesMax);

            for (let ii = 0; ii < numLenses; ii++) {
                let sectionNumForThisLens = Math.floor(Math.random() * numSections);
                lenses.push([
                    0,
                    sectionNumForThisLens,
                    'Evo+ Visian ICL',
                    '1 month',
                    this.formatLensNum(-7.0, this.decimalSeparator, 2),
                    this.formatLensNum(2.0, 2, this.decimalSeparator, 2),
                    this.padNumber(0, 2),
                    decimalSeparatorFormatter('-00.75/ +00.13/090', this.decimalSeparator),
                    this.formatLensNum(0, 2, this.decimalSeparator, 2),
                    sectionNumForThisLens,
                ]);
            }

            lenses.sort();
            lenses.push([
                0,
                'Number of Sections',
                numSections,
                'Number of Lenses',
                numLenses,
                '',
                '',
                null,
                '',
                numSections - 1,
            ]);

            for (let iii = 0; iii < numLenses; iii++) {
                lenses[iii][0] = iii;
            }

            this.preOpReportDebug.eye.lenses = lenses;

            console.log('sections', JSON.stringify(this.sections, null, 2));
            console.log('lenses', JSON.stringify(this.preOpReportDebug.eye.lenses, null, 2));
        },
        loadInventoryLookupData() {
            let eyeName = Object.keys(this.inventoryLookupData.inventoryResults)[0];

            let lensList = [];
            let starSectionIndex = 0;

            //Load Consignment Lenses if present
            if (
                this.inventoryLookupData.inventoryResults[eyeName].consignmentLenses &&
                this.inventoryLookupData.inventoryResults[eyeName].consignmentLenses.length > 0
            ) {
                starSectionIndex = 1;
                this.sections.push(this.t('invLookupReport_Consignment'));
                this.sections.push(this.t('invLookupReport_STAAR'));

                this.inventoryLookupData.inventoryResults[eyeName].consignmentLenses.forEach(
                    (lens) => {
                        lensList.push([
                            lens.isMto ? 'MTO' : lens.lensId,
                            lens.model,
                            lens.version,
                            lens.isMto ? '6 weeks' : '', //Todo - Note that delivery is always blank
                            this.formatLensNum(lens.sphere, 2, this.decimalSeparator, 1),
                            this.formatLensNum(lens.cylinder, 2, this.decimalSeparator, 1),
                            this.padNumber(lens.axis, 2),
                            decimalSeparatorFormatter(lens.expectedRef, this.decimalSeparator),
                            this.formatLensNum(lens.expectedSeq, 2, this.decimalSeparator, 1),
                            0, //Todo - We need to figure out which section the lenses should go into.
                        ]);
                    }
                );
            } else {
                this.sections.push(this.t('invLookupReport_STAAR'));
            }

            //Load Regular Lenses
            this.inventoryLookupData.inventoryResults[eyeName].lenses.forEach((lens) => {
                lensList.push([
                    lens.isMto ? 'MTO' : lens.lensId,
                    lens.model,
                    lens.version,
                    lens.isMto ? '6 weeks' : '', //Todo - Note that delivery is always blank
                    this.formatLensNum(lens.sphere, 2, this.decimalSeparator, 1),
                    this.formatLensNum(lens.cylinder, 2, this.decimalSeparator, 1),
                    this.padNumber(lens.axis, 2),
                    decimalSeparatorFormatter(lens.expectedRef, this.decimalSeparator),
                    this.formatLensNum(lens.expectedSeq, 2, this.decimalSeparator, 1),
                    starSectionIndex,
                ]);
            });

            let calculationVersion = '';
            this.preOpReport.eyes.forEach((eye) => {
                if (eye.name == eyeName) {
                    calculationVersion = eye.calculationVersion;

                    if (eye.calculation?.targetLensString) {
                        this.preOpReport.targetLensName = eye.calculation.targetLensString;

                        this.parseTargetLensValues(this.preOpReport.targetLensName);
                    }

                    if (eye.calculation?.calculationType == 'TICL') this.isToric = true;
                }
            });

            this.preOpReport.eye = {
                calculationVersion: calculationVersion,
                lenses: lensList,
                name: eyeName,
            };
        },
        onlyStaarLenses() {
            if (this.sections.length == 1 && this.sections[0] == this.t('invLookupReport_STAAR'))
                return true;

            return false;
        },
        generateLensTableHeaderRow(sectionName) {
            if (this.isToric) {
                return `
                    <th>${this.t('preopreport_SerialNum')}</th>
                    <th>${this.t('preopreport_Model')}</th>
                    <th>${this.t('invLookupCol_Version')}</th>
                    <th>${this.t('preopreport_Sphere')}</th>
                    <th>${this.t('preopreport_Cylinder')}</th>
                    <th>${this.t('preopreport_Axis')}</th>
                    <th>${this.t('iodreportpopup_Exp_Ref')}</th>
                    <th>${this.t('preopreport_Exp_SEQ')}</th>
                `;
            } else {
                const serialNumberColumn =
                    sectionName === this.t('invLookupReport_Consignment')
                        ? `<th>${this.t('preopreport_SerialNum')}</th>`
                        : '';
                return `
                    ${serialNumberColumn}
                    <th>${this.t('preopreport_Model')}</th>
                    <th>${this.t('invLookupCol_Version')}</th>
                    <th>${this.t('preopreport_Sphere')}</th>
                    <th>${this.t('preopreport_Exp_SEQ')}</th>
                `;
            }
        },
        parseTargetLensValues(targetLensName) {
            //Todo - Probably best in OrionApi so we don't need to do a problematic regex parse
            const regex = /[-+]?(\d*\.\d+|\d+)/g;
            const match = targetLensName.match(regex);

            if (match && match.length) {
                this.targetLensLength = match[0];
                this.targetLensSph = match[1];
                this.targetLensCyl = match[2];
                this.targetLensAxis = match[3];
            }
        },
        parseLensLength(value) {
            const re = new RegExp('(.*?)([.0-9]+$)');
            let reResult = value.match(re);
            return reResult[1];
        },
        lensLengthDiff(value) {
            const re = new RegExp('(.*?)([.0-9]+$)');
            let reResult = value.match(re);
            return reResult[2];
        },
    },
    updated() {
        let lockSection = false;
        if (!this.pagePrinted && !this.debug) {
            var styleSheet = document.createElement('style');
            styleSheet.innerText = `
                body {
                    overflow: hidden;
                }
            `;
            document.head.appendChild(styleSheet);

            let timer = setInterval(() => {
                if (!lockSection && !this.pagePrinted) {
                    lockSection = true;
                    this.pagePrinted = true;
                    this.printReport();
                    clearInterval(timer);
                    lockSection = false;
                }
            }, 500);
        }
    },
    async mounted() {
        //this.activeCustomer.hidePatientNames = true;
        if (this.debug) {
            if (this.debug.startsWith('zzzz')) {
                //Use debug info with randomized data

                //format is zzzz:numSectionsMax:numLensesMax
                const re = /^zzzz:(.+):(.+)$/;
                let str = this.debug;
                const arr = str.match(re);

                console.log('Debug params:', arr);

                //Randomly pick if it's toric
                this.isToric = Math.floor(Math.random() * 2);

                this.generateTestData(arr[1], arr[2]);

                this.preOpReport = this.preOpReportDebug;
                this.parseTargetLensValues(this.preOpReport.targetLensName);
            } else {
                this.preOpReport = this.preOpReportDebug;
                if (this.preOpReport.isToric) this.isToric = true;

                this.parseTargetLensValues(this.preOpReport.targetLensName);
            }
        } else if (!localStorage.inventoryLookupData) {
            alert('Error: localStorage.inventoryLookupData is empty');
        } else {
            this.inventoryLookupData = JSON.parse(localStorage.inventoryLookupData);

            if (
                this.inventoryLookupData.preOpDataSetId == undefined ||
                this.inventoryLookupData.preOpDataSetId == 0
            ) {
                //We have a problem so alert
                //Todo - Does this go to toast?
                alert('Error: The preOpDataSetId is empty');
            } else {
                //This is a normal page load
                await this.blockingRequest('preopreport/fetchPreOpReport', {
                    preOpDataSetId: this.inventoryLookupData.preOpDataSetId,
                });

                this.preOpReport = this.preOpReportRemote;

                this.loadInventoryLookupData();
            }
        }

        this.generatePages();
    },
};
</script>
<style lang="scss" scoped>
#generatingReportContainer {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: white;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

#printReportBtn {
    position: absolute;
    z-index: 2000;
}

#main {
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1500px;
    font-size: 1.3rem;
    z-index: 0;
}

img#headerImg {
    margin-top: 25px;
    margin-bottom: 20px;
}

.preOpReportPage.forcePageBreakBefore img#headerImg {
    padding-top: 30px;
}

.eyeResultHeaderImg {
    height: 50px;
    float: left;
    margin-right: 12px;
    margin-top: 24px;
}

div#headerRight {
    font-size: 2.14rem;
    color: #5b5b5b;
    font-weight: bold;
    margin-right: 62px;
    float: right;
    margin-top: 20px;
}

#titleContainer div#lastName {
    font-size: 4.28rem;
    display: inline-block;
    margin-right: 16px;
    font-weight: 500;
}

#titleContainer div#firstName {
    font-size: 2.57rem;
    display: inline-block;
    font-weight: 400;
}

div#titleContainer {
    padding: 0px 0px 10px 0px;
    margin-right: 62px;
}

#titleContainer.page1 {
    border-bottom: 5px solid black;
}

.names {
    color: #616469;
    margin-left: 15px;
    font-size: 1.4rem;
}

div#patientDataCol2 {
    display: inline-block;
}

div#patientDataCol1 {
    float: left;
    width: 670px;
}

.patientDataItem {
    padding: 5px 0px;
    clear: both;
}

.patientDataItem:first-of-type {
    padding-top: 15px;
}

.preopDataItem {
    padding: 10px 0px;
    clear: both;
}

.patientDataLabel {
    color: #616469;
    float: left;
    width: 150px;
}

.eyeContainer {
    width: 1400px;
    vertical-align: top;
}

.patientDataValue {
    float: left;
    font-weight: bold;
}

.noEmphasis {
    color: grey;
}

.eyeTitle {
    font-size: 5rem;
    font-weight: bold;
    margin-right: 42px;
    margin-left: 13px;
    margin-top: 12px;
    margin-bottom: auto;
}

.sectionTitle {
    font-size: 3.857rem;
    font-weight: 500;
    margin-left: 12px;
    padding-top: 34px;
    padding-left: 13px;
    padding-bottom: 10px;
}

.eyeResultHeader {
    min-height: 94px;
    border-bottom: 5px solid black;
    display: flex;
}

.calculationTitleContainer {
    margin-top: 12px;
    margin-right: 50px;
    font-size: 1rem;
    text-align: right;
    white-space: nowrap;
    padding-top: 6px;
}

#resultsContainer {
    padding-top: 80px;
    height: fit-content;
    clear: both;
}

.resultsContainerPageX {
    padding-top: 40px;
}

#resultsContainerRow {
}

th {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
}

table {
    width: 96%;
    padding: 10px;
    border-spacing: 0px;
    border-collapse: collapse;
    margin: 10px;
    font-size: inherit;
}

tr.headerRow {
    background-color: #f9f9f9;
}

td {
    font-weight: bold;
    text-align: center;
}

th,
td {
    padding: 12px;
}

.preopDataValue {
    font-weight: bold;
}

th.calculationTableHeader1 {
    color: black;
    border-bottom: 3px solid black;
    font-size: 1.07rem;
    font-weight: normal;
}

tr.selectedCalcRow {
    border: 3px solid black;
    background-color: #f1f1f1;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
}

.emptyData {
    color: #aeaeae;
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 167px;
}

div#patientDataContainer {
    padding-top: 8px;
}

div#pageHeader {
    margin: 40px 0px 5px 0px;
}

.preOpReportPage:first-of-type #pageHeader {
    padding-top: 50px;
}

.preOpReportPage:first-of-type #resultsContainer {
    padding-top: 70px;
}

div#calculationFooterRightTitle,
#calculationFooterRightValue {
    float: right;
    margin-right: 11px;
}

#calculationFooterRightValue {
    font-weight: bold;
}

div#calculationFooterLeftContainer {
    margin-left: 15px;
}

td {
    text-align: center;
}

th {
    text-align: center;
}

.preopRowTitle {
    text-align: left;
    font-weight: normal;
}

#whiteSpacer {
    width: 25px;
}

#table1 {
    margin-bottom: 30px;
}

#lensesContainer {
}

#eyeResult {
    border: 5px solid black;
    height: 1515px;
    text-align: left;
    min-height: 1515px;
}

#eyeResult.eyeResultPageX {
    min-height: 1660px;
    max-height: 1660px;
}

#footerLabel {
    color: #616469;
    float: left;
    display: flex;
    width: 100%;
    margin-top: 25px;
}

#footerLabelLeft {
    white-space: nowrap;
}

#targetLensLabel {
    color: #616469;
    font-size: 1.3rem;
    width: 100%;
    margin-right: 55px;
    margin-top: 9px;
    text-align: right;
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
    max-width: 460px;
}

#targetLensName {
    font-size: 1.857rem;
    font-weight: bold;
    white-space: nowrap;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 25px;
    text-align: right;
}

.patientDataContainerPageX {
    float: right;
    margin-right: 63px;
    margin-top: 60px;
}

#inventoryLevelsWarning {
    color: #f03738;
    font-weight: bold;
    font-size: 2rem;
    width: 100%;
    text-align: center;
    padding-top: 45px;
}

.preOpReportPage {
    page-break-after: always;
    clear: both;
    min-height: 2100px;
    max-height: 2100px;
    height: 2100px;
    padding-left: 50px;
}

.forcePageBreakBefore {
    page-break-before: always;
}

.sectionTable {
    padding-bottom: 50px;
}

img#footerImg {
    width: 424px;
    padding-bottom: 16px;
}

#lensTypeImg {
    width: 175px;
    margin-top: -10px;
    padding-left: 2px;
}
.z-index-999 {
    z-index: 999;
}
</style>
<style lang="scss">
.inventoryLookupLensHr {
    background-color: #f9f9f9;
}

.inventoryLookupLensHr > th {
    text-align: center;
    padding: 12px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
}

#generatingReport {
    font-size: 50px;
}

.dontShowSectionTitle {
    opacity: 0;
    height: 0px;
    padding-top: 0px;
}

.cellRedTextFound {
    color: red;
    display: inline-block;
}
</style>
